import * as React from 'react';
import classNames from 'classnames';
import styles from './Navbar.module.scss';
import { Link } from 'gatsby';
import logo from '../../../assets/images/logo_full_purple.png';

export interface NavbarProps {}

const Navbar: React.FunctionComponent<NavbarProps> = () => {
  React.useEffect(() => {
    (window as any).docsearch({
      apiKey: 'fbd960b23315469fdf8773b2599cdfb7',
      indexName: 'opinous',
      inputSelector: '.searchinput',
      debug: true,
    });
  }, []);

  return (
    <div className={styles.Navbar}>
      <div className={styles.Topper} />
      <nav className={styles.Nav}>
        <Link to='/' className={styles.Brand}>
          <img src={logo} />
        </Link>
        <div className={classNames(styles.Search)}>
          <i className='far fa-search' />
          <input type='text' placeholder='Search' className='searchinput' />
        </div>
        <ul className={styles.NavList}>
          <li>
            <Link to='/reference' activeClassName={styles.NavItemActive} partiallyActive={true}>
              Documentation
            </Link>
          </li>
          <li>
            <a onClick={() => (window as any).Intercom('show')}>
              Developer Support
            </a>
          </li>
          <li>
            <a href='https://manage.opinous.com' target='_blank'>
              Dashboard <i className='far fa-external-link-square' />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}
